.export-chat {
  .modal-dialog {
    max-width: 800px;
  }
  .scrollarea {
    max-height: 55vh;
    overflow-y: scroll;
  }
  .btn-primary, button.btn-primary {
    background-color: #0c1451; //#010a13
    font-family: 'Inter', sans-serif;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #0c1451;
    padding: 6px 12px;
    border-radius: 6px;
  }  
  .title, .created {
    display: block;
    float: left;
  }
}