.order {
  .form-label {
    font-size: 1.5em;
    color: #444444;
  }

  .caseslist > table {
    height:220px;
  }
  .label {
    text-align: right;
  }
}