.typing-indicator {
  background-color: #E6E7ED;
  width: auto;
  border-radius: 50px;
  padding: 20px;
  display: table;
  margin: 0 auto;
  position: relative;
  -webkit-animation: 3s bulge infinite ease-out;
          animation: 3s bulge infinite ease-out;
}
.typing-indicator:before, .typing-indicator:after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #a3b4ff;
  margin: 1px;
}
.typing-indicator:after {
  height: 10px;
  width: 10px;
  left: -10px;
  bottom: -10px;
}
.typing-indicator span {
  height: 12px;
  width: 12px;
  padding: 1px;
  float: left;
  margin: 0 1px;
  background-color: #0433b3;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}
.typing-indicator span:nth-of-type(1) {
  margin: 1px;
  -webkit-animation: 2s blink infinite 0.6666s;
          animation: 2s blink infinite 0.6666s;
}
.typing-indicator span:nth-of-type(2) {
  margin: 1px;
  -webkit-animation: 2s blink infinite 1.3333s;
          animation: 2s blink infinite 1.3333s;
}
.typing-indicator span:nth-of-type(3) {
  margin: 1px;
  -webkit-animation: 2s blink infinite 1.9999s;
          animation: 2s blink infinite 1.9999s;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}
@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}

.dot-indicator {
  display: flex;
  flex: none;
  align-items: flex-end;
  margin: 0 0 !important;
  
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 52px;
    // height: 40px;
    margin: 0px 8px !important;
    // background-color: #f0f1f1;
    // border-radius: 12px;
  }
  
  span {
    width: 4px;
    height: 4px;
    margin-right: 4px !important;
    // background-color: #57585a;
    background-color: #fff;
    border-radius: 50%;
    animation-name: bounce;
    animation-duration: 1.3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    &:first-of-type {
      margin: 0px 4px;
    }
    &:nth-of-type(2) {
      animation-delay: 0.15s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.3s;
    }
  }
  
  @keyframes bounce {
    0%,
    60%,
    100% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-4px);
    }
  }  
}