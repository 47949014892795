@import './shared.scss';
@import "./breakpoints";
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.login {
  .left-logo-area {
    width: 400px;
    height:200px;
    position: relative;
    left: 0;
    float: left;
    .left-logo {
      width: 100%;
      object-fit: contain;
    }
  }
  .right-logo-area {
    width: 400px;
    height:200px;
    position: relative;
    float: right;
    right: 0;
    .right-logo {
      width: 100%;
      object-fit: contain;
    }
  }
  .poweredby {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 24px;
  }
}