@use 'three-dots';

.cases {
  .form-label {
    font-size: 1.5em;
    color: #444444;
    margin-top:10px;
  }

  .caseslist > table {
    height:220px;
  }
  .label {
    text-align: right;
  }
  .add-case {
    margin-top:4px;
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;
    background: rgba(0, 0, 0, 0.75);
  }
  .tooltip-active-element {
    position: relative;
    z-index: 3;
    background-color: #fff;
  }





  .chatBody {
    div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,
    fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{
      margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{
      display:block
    }
    body{
      line-height:1
    }
    ol,ul{
      list-style:none
    }
    blockquote,q{
      quotes:none
    }
    blockquote:before,blockquote:after,q:before,q:after{
      content:'';content:none
    }
    table{
      border-collapse:collapse;border-spacing:0
    }

    --white: #fff;
    --black: #000;
    --bg: #f8f8f8;
    --grey: #999;
    --dark: #1a1a1a;
    --light: #e6e6e6;
    --lightgrey: #e6e6e6;
    --contentgrey: #f1f1f1;
    --hightlightgrey: #d8d8d8;
    --wrapper: 1000px;
    --blue: #0268bb;
    --green: #193d37;
    --red: #af3030;

    $color_1: var(--dark);
    $color_2: var(--grey);
    $color_3: var(--white);
    $font-family_1: "Source Sans Pro", sans-serif;
    $font-size_1:18px;
    $font-size_2:20px;
    $font-size_3:13px;
    $font-size_4:30px;
    $button-green: var(--green);
    $button-red: var(--red);
    $background-color_1: var(--bg);
    $background-color_2: var(--white);
    $background-color_3: var(--light);
    $background-color_4: #eceff1;
    $background-color_5: var(--blue);
    $background-color_6: var(--lightgrey);
    $background-color_7: var(--contentgrey);
    $background-color_8: var(--hightlightgrey);
    $border-color_1: var(--light);

    background-color: $background-color_1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: $font-family_1;
    font-weight: 400;
    
    @keyframes slideFromLeft {
      0% {
        margin-left: -200px;
        opacity: 0;
      }
      100% {
        margin-left: 0;
        opacity: 1;
      }
    }
    @-webkit-keyframes slideFromLeft {
      0% {
        margin-left: -200px;
        opacity: 0;
      }
      100% {
        margin-left: 0;
        opacity: 1;
      }
    }
    @keyframes slideFromRight {
      0% {
        margin-right: -200px;
        opacity: 0;
      }
      100% {
        margin-right: 0;
        opacity: 1;
      }
    }
    @-webkit-keyframes slideFromRight {
      0% {
        margin-right: -200px;
        opacity: 0;
      }
      100% {
        margin-right: 0;
        opacity: 1;
      }
    }
    * {
      box-sizing: border-box;
      &:before {
        box-sizing: border-box;
      }
      &:after {
        box-sizing: border-box;
      }
    }
    .wrapper {
      position: relative;
      left: 50%;
      width: 100%;
      min-width: var(--wrapper);
      height: 100vh;
      transform: translate(-50%, 0);
    }
    .container {
      // position: relative;
      // top: 50%;
      // left: 50%;
      width: 80%;
      height: 100%;
      background-color: $background-color_2;
      // transform: translate(-50%, -50%);
      .left {
        float: left;
        width: 340px;
        height: 100%;
        border: 1px solid var(--light);
        font-family: $font-family_1;
        font-size: $font-size_3;
        font-weight: normal;
        background-color: $background-color_7;
        .top {
          position: relative;
          width: 100%;
          height: 235px;
          padding:0;
          // &:after {
          //   position: absolute;
          //   bottom: 0;
          //   left: 50%;
          //   display: block;
          //   width: 80%;
          //   height: 1px;
          //   content: "";
          //   background-color: $background-color_3;
          //   transform: translate(-50%, 0);
          // }
          .logo-area {
            padding: 5px 5px;
            background-color:#c8cacb; //#101091;
            height: 140px;
            .Image {
              &.left-logo {
                //width: 70%;
                //margin:5px 5px;
                //width: 100%;
                height: 100%;
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                //object-fit:cover;
              }
            }
          }
          input {
            float: left;
            width: 188px;
            height: 42px;
            padding: 0 15px;
            border: 1px solid var(--light);
            background-color: $background-color_4;
            border-radius: 11px;
            font-family: $font-family_1;
            font-weight: 400;
            &:focus {
              outline: none;
            }
          }
          a.search {
            display: block;
            float: left;
            width: 42px;
            height: 42px;
            margin-left: 10px;
            border: 1px solid var(--light);
            background-color: $background-color_5;
            background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/name-type.png");
            background-repeat: no-repeat;
            background-position: top 12px left 14px;
            border-radius: 50%;
          }
          .searchgroup {
            float: left;
            width: calc(100% - 10px);
            padding:0 0 0 10px;
            margin:30px 0 0 0;
            .searchicon {
              position: absolute;
              margin:15px;
              z-index: 1;
            }
            .closeicon {
              position: absolute;
              top:14px;
              right:15px;
              z-index: 1;
              color: #000;
            }
            input {
              text-indent: 20px;
              width: 100%;
            }
            button {
              &:before {
                display: inline-block;
                float: left;
                width: 20px;
                height: 42px;
                margin-left: 11px;
                content: "x";
                color: #0c1451;
                background-repeat: no-repeat;
                background-position: center;
              }
              background: none;
              border: none;
              padding: 0 8px 0 0;
              margin: 0;
              height: 40px;
            }
            button:hover,.btn:hover,.btn.show {
              background: none !important;
              background-color: none !important;
              border: none;
            }
          }
        }
        .bottom {
          width: 100%;
          height:75px;
          button.search {
            position: relative;
            display: block;
            top:35%;
            height: 42px;
            margin: 0 auto;
            font-family:$font-family_1;
            font-size:13px;
            font-weight: normal;
            border: 1px solid var(--light);
            background-color: #406b64;
            background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/name-type.png");
            background-repeat: no-repeat;
            background-position: top 12px left 35px;
            border-radius: 10px 10px 10px 10px;
            padding: 5px 60px;
          }
          button.search:hover, button.search:active {
            background-color: $button-green !important;
          }
        }
        .middle {
          height: calc(100% - 329px);
          .researchtitle {
            text-align: left;
            height:35px;
            font-size: 15px;
            font-family: $font-family_1;
            font-weight: normal;
            margin-top:5px;
            margin-left: 25px;
            span {
              padding-left: 15px;
            }
          }
          .researchsessions {
            height: calc(100% - 40px);
            overflow-y: auto;
            overflow-x: hidden;
          }
          .people {
            margin-left: -1px;
            border-right: 1px solid var(--light);
            border-left: 1px solid var(--light);
            width: calc(100% + 2px);
            .person {
              position: relative;
              width: 100%;
              height:45px;
              cursor: pointer;
              background-color: $background-color_6;
              &:after {
                position: absolute;
                bottom: 0;
                left: 50%;
                display: block;
                width: 80%;
                height: 1px;
                content: "";
                background-color: $background-color_3;
                transform: translate(-50%, 0);
              }
              img {
                float: left;
                width: 40px;
                height: 40px;
                margin-right: 12px;
                border-radius: 50%;
                -o-object-fit: cover;
                object-fit: cover;
              }
              .name {
                font-size: $font-size_3;
                color: $color_1;
                font-family: $font-family_1;
                font-weight: normal;
                line-height: 22px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space:nowrap;
                padding: 12px 10% 16px 8px;
                width:100%;
                display:inline-block;
              }
              &:hover {
                .name {
                  padding:12px 20% 16px 8px;
                }
              }
            .time {
                font-size: $font-size_3;
                font-size: 90%;
                position: absolute;
                top: 25px;
                right: 10%;
                padding: 0 0 5px 5px;
                color: $color_2;
              }
              .preview {
                font-size: 14px;
                display: inline-block;
                overflow: hidden !important;
                width: 70%;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: $color_2;
              }
              &:hover {
                margin-left: -1px;
                border: 0;
                background-color: $background-color_8;
                width: calc(100% + 2px);
                span {
                  color: $color_3;
                  background: transparent;
                }
                &:after {
                  display: none;
                }
              }
              .compare, .delete {
                display: none;
                font-size: 20px;
                line-height: 20px;
              }
            }
            .person:hover {
              .compare {
                display: inline-block;
                position: absolute;
                z-index: 2;
                right: 10px;
                top: 12px;
                color: $button-green;
                font-weight: 900;
              }
              .delete {
                display: inline-block;
                position: absolute;
                z-index: 2;
                right: 40px;
                top: 12px;
                color: $button-red;
              }
            }
            .person.active {
              margin-top: -1px;
              margin-left: -1px;
              border: 0;
              background-color: $background-color_5;
              width: calc(100% + 2px);
              span {
                color: $color_3;
                background: transparent;
              }
              &:after {
                display: none;
              }
            }
          }
        }
        .toolbar {
          position: absolute;
          bottom: 75px;
          left: 340px;
          z-index: 2;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          background-color: #406b64;
          box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.3);
          width: 45px;
          button {
            width: 45px;
            border-radius: 0;
            background-color: transparent;
            text-align: center;
            padding:5px 9px 12px 9px;
          }
          :first-child {
            border-top-right-radius: 4px;
          }
          :last-child {
            border-bottom-right-radius: 4px;
          }
          .btn:hover, .btn.active {
            background-color: $button-green !important;
          }
        }
      }
      .right {
        position: relative;
        float: left;
        width: calc(100% - 340px);
        height: 100%;
        .logo-area {
          width: 100%;
          height: 140px;
          background-color: #d5d5d5;// var(--blue);// $background-color_4;
          text-align: right;
          > img {
            padding: 5px 10px;
            width: auto;
            height: 100%;
          }
        }
        .top {
          // width: 100%;
          // height: 137px;
          // background-color: $background-color_4;
          max-width: 100%;
          display:flex;
          justify-content: center;
          background-color: $background-color_4;
          height: 137px;
          .content {
            display: inline-block;
            width: 800px;
            justify-content: initial;
          }
          .title {
            display: block;
            font-weight: bold;
            font-size: $font-size_2;
            font-family: $font-family_1;
            color: #494949;
            .save {
              font-size: 35px;
              color:$button-green;
            }
            .close {
              font-size: 35px;
              color:$button-red;
            }
            .edit {
              border-bottom: 1px solid #939393;
              font-size: 80%;
              color: #939393;
            }
          }
          .created, .last {
            display: block;
            font-size: $font-size_3;
            font-weight: normal;
            font-family: $font-family_1;
          }
          span {
            font-size: 15px;
            color: $color_2;
            .name {
              color: $color_1;
              font-family: $font-family_1;
              font-weight: 600;
            }
          }
        }
        .bottom {
          max-width: 100%;
          display:flex;
          justify-content: center;
          border-bottom: 1px solid #f5f5f5;
          height: calc(100vh - 277px);
          &.sidechat {
            //position: absolute;
          }
          .content {
            display: inline-block;
            width: 800px;
            justify-content: initial;
            // &.sidechat {
            //   width:calc(50%);
            // }
            .indicator {
              position: absolute;
              left:0;
              margin:6px 13px;
              // z-index: 30;
              &.hide {
                display: none;
              }
            }
            .waiting {
              position: absolute;
              left: 50%;
              top:50%;
              transform: translate(-50%, -50%);
              z-index: 1000;
            }
            .chat {
              position: relative;
              display: none;
              overflow: hidden;
              height: 100%;
              justify-content: flex-end;
              // flex-direction: column;
              .scrollarea {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 0 35px 2px;
                margin:40px 0 90px;
                min-height: calc(100vh - 410px);
                scrollbar-width: thin;
                .mecircle, .youcircle {
                  display: inline-block;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  line-height: 40px;
                  text-align: center;
                  font-weight: 100;
                  color: #fff;
                  margin:20px 0 0 0;
                }
                .youcircle {
                  background-color: #d8d8d8;
                  margin-left: 10px;
                }
                .youcircle.selectable {
                  cursor: pointer;
                }
                .youcircle.selected {
                  background-color: #00b0ff;
                  font-size: $font-size_4;
                  line-height: 37px;
                }
                .youcircle.selectable:hover {
                  background-color: #00b0ff;
                }
                .mecircle {
                  background-color: #406b64;
                  margin-right: 10px;
                }
                .bubble {
                  font-size: 16px;
                  position: relative;
                  display: inline-block;
                  margin-bottom: 8px;
                  padding: 13px 14px;
                  vertical-align: top;
                  margin:15px 0;
                  border-radius: 5px;
                  width: calc(100% - 100px);
                  [contenteditable] {
                    outline: 0px solid transparent;
                  }
                  strong {
                    font-weight: bolder;
                  }
                  ol {
                    list-style-type: decimal;
                    padding: revert;
                    margin: revert;
                  }
                  li {
                    padding: revert;
                    margin: revert;
                  }
                  .edit, .update, .cancel {
                    display: none;
                    font-size: 20px;
                    line-height: 20px;
                  }
                  &:hover {
                    .edit, .update, .cancel {
                      display: inline-block;
                      position: absolute;
                      z-index: 2;
                      right: 10px;
                      bottom: 10px;
                      color: var(--grey);
                    }
                    .update {
                      right: 40px;
                      color: var(--green);
                    }
                    .cancel {
                      right: 10px;
                    }
                  }
                  .docref {
                    color:#91c4ff;
                  }
                }
                .bubble.you {
                  color: $color_3;
                  background-color: $background-color_5;
                  -webkit-animation-name: slideFromLeft;
                  animation-name: slideFromLeft;
                  margin-left: 50px;
                  &:before {
                    left: -3px;
                    background-color: $background-color_5;
                  }
                  &.wait {
                    width: 70px;
                    height: 40px;
                    margin: 18px 0 0 calc(100% - 120px);
                  }
                }
                .bubble.me {
                  color: $color_1;
                  background-color: $background-color_4;
                  -webkit-animation-name: slideFromRight;
                  animation-name: slideFromRight;
                  &:before {
                    right: -3px;
                    background-color: $background-color_4;
                  }
                }
                .conversation-start {
                  position: relative;
                  width: 100%;
                  margin-bottom: 27px;
                  text-align: center;
                  span {
                    font-size: 14px;
                    display: inline-block;
                    color: $color_2;
                    &:before {
                      position: absolute;
                      top: 10px;
                      display: inline-block;
                      width: 30%;
                      height: 1px;
                      content: "";
                      background-color: $background-color_3;
                      left: 0;
                    }
                    &:after {
                      position: absolute;
                      top: 10px;
                      display: inline-block;
                      width: 30%;
                      height: 1px;
                      content: "";
                      background-color: $background-color_3;
                      right: 0;
                    }
                  }
                }
              }
              .hider {
                width: calc(100% - 20px);
                height:80px;
                position: absolute;
                background-color: #fff;
                bottom: 0;
                left: 0;
                .write {
                  position: absolute;
                  bottom: 15px;
                  left: 30px;
                  height: 42px;
                  padding-left: 8px;
                  border: 1px solid var(--light);
                  background-color: $background-color_4;
                  width: calc(100% - 58px);
                  border-radius: 5px;
                  input {
                    font-size: 16px;
                    float: left;
                    width: calc(100% - 78px);
                    height: 40px;
                    padding: 0 10px;
                    color: $color_1;
                    border: 0;
                    outline: none;
                    background-color: $background-color_4;
                    font-family: $font-family_1;
                    font-weight: 400;
                  }
                  .write-link.attach {
                    &:before {
                      display: inline-block;
                      float: left;
                      width: 20px;
                      height: 42px;
                      content: "";
                      background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/attachment.png");
                      background-repeat: no-repeat;
                      background-position: center;
                    }
                  }
                  .write-link.smiley {
                    &:before {
                      display: inline-block;
                      float: left;
                      width: 20px;
                      height: 42px;
                      content: "";
                      background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/smiley.png");
                      background-repeat: no-repeat;
                      background-position: center;
                    }
                  }
                  .write-link.send {
                    &:before {
                      display: inline-block;
                      float: left;
                      width: 20px;
                      height: 42px;
                      margin-left: 11px;
                      content: "";
                      background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/382994/send.png");
                      background-repeat: no-repeat;
                      background-position: center;
                    }
                  }
                  button.write-link.send {
                    background: none;
                    border: none;
                    padding: 0 8px 0 0;
                    margin: 0;
                    height: 40px;
                  }
                }
              }
              .closeSide {
                position: absolute;
                top:10px;
                right:30px;
                font-size: $font-size_2;
              }
            }
            &.tooltip-active-element {
              .chat.active-chat {
                .scrollarea {
                  margin-top:0;
                }
              }
            }
            .chat.active-chat {
              display: flex;
              flex-direction: column;
              .bubble {
                transition-timing-function: cubic-bezier(0.4, -0.04, 1, 1);
                &:nth-of-type(1) {
                  -webkit-animation-duration: 0.15s;
                  animation-duration: 0.15s;
                }
                &:nth-of-type(2) {
                  -webkit-animation-duration: 0.3s;
                  animation-duration: 0.3s;
                }
                &:nth-of-type(3) {
                  -webkit-animation-duration: 0.45s;
                  animation-duration: 0.45s;
                }
                &:nth-of-type(4) {
                  -webkit-animation-duration: 0.6s;
                  animation-duration: 0.6s;
                }
                &:nth-of-type(5) {
                  -webkit-animation-duration: 0.75s;
                  animation-duration: 0.75s;
                }
                &:nth-of-type(6) {
                  -webkit-animation-duration: 0.9s;
                  animation-duration: 0.9s;
                }
                &:nth-of-type(7) {
                  -webkit-animation-duration: 1.05s;
                  animation-duration: 1.05s;
                }
                &:nth-of-type(8) {
                  -webkit-animation-duration: 1.2s;
                  animation-duration: 1.2s;
                }
                &:nth-of-type(9) {
                  -webkit-animation-duration: 1.35s;
                  animation-duration: 1.35s;
                }
                &:nth-of-type(10) {
                  -webkit-animation-duration: 1.5s;
                  animation-duration: 1.5s;
                }
              }
              .summarizer {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height:40px;
                button {
                  font-size:$font-size_3;
                  margin:5px;
                  padding: 7px 15px;
                  &.cancel {
                    
                  }
                  &.submit {
                    
                  }
                  &.selectall {
                    
                  }
                }
              }
            }
          }
          .summary {
            display: inline-block;
            width: 800px;
            justify-content: initial;
            .editor {
              position: relative;
              display: block;
              display: flex;
              overflow: hidden;
              height: 100%;
              justify-content: flex-end;
              // flex-direction: column;
              .scrollable {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                overflow-x: hidden;
                padding: 30px 35px 92px;
              }
            }
            .close-summary {
              position: absolute;
              right:30px;
              top:10px;
              font-size: $font-size_2;
              z-index: 1;
            }
          }
        }
      }
    }        
  }
}


