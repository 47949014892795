@import './_variables.scss';

.dashboard {
  .sidebar {
    float: left;
    width: var(--sidebar-width);
  }
  .main {
    width: calc(100vw - var(--sidebar-width));
    padding-left: .19rem !important;
    gap: 0.625rem;
    //transition: width 1s ease-in-out;
  }
  &.closed {
    .sidebar {
      width: 5rem;
      //transition: width 1s ease-in-out;
    }
    .main {
      width: calc(100vw - var(--closedbar-width) - 1.1rem);
      //transition: width 1s ease-in-out;
    }
  }
}