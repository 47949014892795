.roles {
  .form-label {
    font-size: 1.5em;
    color: #444444;
  }

  .rolelist > table {
    height:220px;
  }
  .label {
    margin:10px 0 5px 0;
    text-align: left;
  }
}