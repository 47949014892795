// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$screen-1024: 1024px;

$screen-h-684: 684px;

:export {
  small: $screen-sm-min;
  medium: $screen-md-min;
  large: $screen-lg-min;
  extra: $screen-xl-min;
  _1024: $screen-1024;
  h_684: $screen-h-684;
}