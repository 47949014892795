@import './_variables.scss';
@import './shared.scss';


  .input-area {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
    margin-top: 1.5rem;
    .input-group {
      max-width: var(--message-area-width);
    }
    input.form-control {
      border-radius: 1rem;
      padding: 0.25rem 0.5rem 0.25rem 1.5rem;
      border: 1px solid rgba(28, 50, 79, 0.38);
      height: 3.8rem;
    }
    .input-group-text {
      border-radius: 1rem;
      border: 1px solid rgba(28, 50, 79, 0.38);
      padding: 0.25rem 0.5rem 0.25rem 1.5rem;
      height: 3.8rem;
      &.export {
        &::before {
          content:url('../images/export.svg');
          height: 1.8rem;
          margin-left: -0.7rem;
        }
      }
      &.send {
        &::before {
          content:url('../images/rightarrow.svg');
          height: 2.2rem;
          margin-left: -1rem;
        }
      }
    }
  }


  .chatbox-input-area {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
    margin-top: 1.5rem;

    .chatbox-input {
      display: flex;
      justify-content: center;
      gap: 0.625rem;
      margin-top: 1.5rem;
      border-radius: 2rem;
      background: #f4f4f4;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0.625rem;
      max-width: var(--message-area-width);
      input {
        border-radius: 1.5rem;
        background: #fff;
        padding: 0.25rem 9rem 0.25rem 3.5rem;
        width: calc(var(--message-area-width) - 1.25rem);
        border: none;
        height: 3.8rem;
        &:focus {
          outline: none;
          border: none;
        }      
      }
      .circle {
        padding: 0.2rem .2rem;
        color: white;
        width: 2.3rem;
        height: 2.3rem;
        line-height: 1.95rem;
        background-color:#097FAA;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        margin:0 0 0 .6rem;
      }
      .send {
        position: absolute;
        border-radius: 2rem;
        background: #F4F4F4;
        margin:0 0 0 calc(var(--message-area-width) - 6.25rem);
        display: flex;
        padding: 0.375rem 1.5625rem;
        width: 4.3125rem;
        height: 2.25rem;      
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;      
        font: var(--send-button-text);
        border: none;
      }
      .ai-enhance {
        position: absolute;
        display: flex;
        flex-shrink: 0;      
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        align-self: stretch;
        background: none;
        border: none;
        margin:.3rem 0 0 calc(var(--message-area-width) - 9.25rem);
        &::before {
          content:url('../images/ai-enhance.svg');
        }
      }
    }
  }