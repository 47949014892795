@import './_variables.scss';

.history {
  width: calc(100% - 3rem);
  margin: 0 auto;
  .closed &  {
    width: 100%;
    margin: 0 auto;
  }
  .clock {
    display: none;
    .closed &  {
      display: block;
      text-align: center;
    }
    &::before {
      content:url('../images/clock.svg');
      transition: width 1s ease-in-out, margin 1s ease-in-out;
    }      
  }
  .entry {
    .closed &  {
      display: none;
    }
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    .title {
      color: var(--Tertiary-Text);
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        //position: relative;
        div {
          white-space: nowrap;
          width: calc(var(--sidebar-width) - 3.875rem);
          overflow-x: hidden;
          /*scrollbar-width: thin;
          scroll-behavior: smooth;
          scrollbar-gutter: stable;*/
          text-overflow: ellipsis;
          cursor: pointer;
          &::-webkit-scrollbar{
            height: 4px;
            width: 4px;
            background: gray;
          }
          &.has-ellispsis:hover {
            display: block;
            position: absolute;
            overflow-x: auto;
            height: 2rem;
            line-height: 1.8rem;
            width: auto;
            z-index: 1;
            margin:1px 0 0 -1px;
            background: var(--response-primary);
            border: 1px solid var(--Heading-Text);
          }
        }
        height: 2rem;
        line-height: 2rem;
        //display: flex;
        padding: 1.5rem 0.875rem;
        /*flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.5rem;
        align-self: stretch;*/
      }
    }
  }
}