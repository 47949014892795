@import './_variables.scss';

.chat-box {
//  display: flex;
//  align-items: flex-start;
  height: calc(100vh - var(--header-height));
  width: 100%;
  .title {
    padding: 1rem 0.625rem 1rem 0.625rem;
    text-align: center;
    gap: 0.625rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.60);
    span {
      font: var(--chat-title-text);
    }
  }
  .message-area {
    display: flex;
    //padding: 0.625rem;
    //height: calc(100vh - (var(--header-height) + 9rem));
    justify-content: center;
    align-items: end;
    width: 100%;
    gap: 0.625rem;
    overflow: hidden;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    &:hover {
      overflow-y: scroll;
    }

    .messages {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
      max-width: var(--message-area-width);
      height: calc(100vh - (var(--header-height) + 13rem));
      //margin-bottom: 8rem;
      .initial {
        display: flex;
        align-self: stretch;
        flex-direction: column;
        font: var(--heading-xxl);
        background:linear-gradient(90deg, #455071 0%, #182848 100%);//var(--primary-gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        span:first-child {
          //color: var(--heading-xxl-color);
          background:var(--heading-xxl-name-color);
          background-clip: text;
        }
      }
      .message {
        display: flex;
        padding-left: 5rem;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.5rem;
        align-self: stretch;
        margin:1.75rem 0;
        .bubble {
          display: flex;
          padding: 1rem 1.5rem 1.5rem 1rem;
          flex-direction: column;
          //align-items: flex-start;
          gap: 0.625rem;
          //align-self: stretch;
          border-radius: 1rem;
          font:var(--chat-box-text);
        }
        .user {
          display: flex;
          align-items: center;
          gap: 0.625rem;
          .name {
            flex:10;
            color: #1A2A4A;
            font: var(--default-text);
            margin: .5rem 0;
          }
          .button {
            /*padding:.4rem;
            background-color:#097FAA;
            border-radius: 50%;
            display: flex;
            align-items: center;
            text-align: center;
            width: 2.3rem;
            height: 2.3rem;*/
            background: transparent;
            font:var(--label-field);
            color:var(--button-primary);
            .circle {
              padding: 0.2rem .2rem;
              color: white;
              width: 2.3rem;
              height: 2.3rem;
              line-height: 1.95rem;
              background-color:#097FAA;
              border-radius: 50%;
              display: inline-block;
              text-align: center;
              vertical-align: middle;
              position: relative;
            }
          }
        }
        &.question {
          padding-left: 5rem;
          padding-right: 0;
          color: var(--question-chat-color);
          align-items: flex-end;
          .bubble {
            background: var(--question-primary);
            margin-right: 1.5rem;
            align-items: flex-end;
          }
        }
        &.response {
          padding-left: 0;
          padding-right: 5rem;
          color: var(--response-chat-color);
          align-items: flex-start;
          .bubble {
            margin-left: 1.5rem;
            //background: var(--response-primary);
            align-items: flex-start;
          }
        }
      }
    }
  }
}