@import './shared.scss';
@import "./breakpoints";
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './noauthlayout.scss';
//$bg-mb:url(../images/reg-bg.png);


.register {
  .layout-area {
    @media screen and (max-width: $screen-sm-min) {
      //background: $bg-mb center repeat-x;
      background-size: 100%;
      background-position-y: bottom;
    }
  }
  .layout-area {
    h1 {
      @media screen and (max-width: $screen-sm-min) {
        margin: 25px 0 24px -15px;
      }      
    }
  }  
}

.layout-area {
  .form.registration {
    .mb-3:nth-child(-n+5) {
      max-width: 350px;
      min-width: 350px;
      margin: 0 auto;
      @media screen and (max-width: $screen-sm-min) {
        margin:13px auto;
      }
      input[type="text"] {
        border-radius: 8px;
        border: 2px solid #8DD9AB;
        background: #FFF;
      }
      input[type="radio"] {
        width: 6px;
        padding: 6px;
        display: inline-block;
        margin-right: 5px;
      }
      @media screen and (max-width: $screen-sm-min) {
        max-width: 80%;
        min-width: 80%;
        color: #286741;
        font-size: 14px;
        .form-label {
          margin-bottom: 0;
        }
        small {
          display: none;
          visibility: hidden;
        }
        input[type="text"] {
          border-radius: 8px;
          border: 2px solid #8DD9AB;
          background: #FFF;          
        }
      }
      .accounttype {
        h4 {
          color: #FFF;
          text-align: center;
          font-family: 'Inter', sans-serif;
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.333px;
          img {
            position: relative;
            top:-22px;
            right: -11px;
          }
        }
        label {
          color: #FFF;
          font-family: 'Inter', sans-serif;
          font-size: 19px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin:18px 0 18px 0;
        }
        @media screen and (max-width: $screen-sm-min) {
          h4 {
            color: #286741;
            font-weight: 600;
          }
          label {
            color:initial;
            margin: 10px 0 18px 0;
            font-size: 18px;
          }
          margin-top:40px;
          .row {
            margin-top:10px;
          }
        }
      }
    }
    .mb-3.register {
      @media screen and (max-width: $screen-sm-min) {
        margin-top:45px;
      }
      button {
        color: #286741;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        display: flex;
        width: 243px;
        height: 65px;
        flex-direction: row;
        justify-content: center;
        flex-shrink: 0;
        background: white;
        border: none;
        border-radius: initial;
        @media screen and (max-width: $screen-sm-min) {
          background-color: #fff;
          color: #286741;      
          width: 200px;
          height: 43px;
          line-height: 28px;
          font-family: 'Inter', sans-serif;
          font-size: 25px;
          font-weight: 300;
        }
      }
      .btn-primary:hover {
        background:#f7fffb;
        @media screen and (max-width: $screen-sm-min) {
          background:#204E2D;
        }
      }
      .btn-primary:active {
        background:#0a58ca;
        color: #FFF;
      }
    }
    .mb-3.terms {
      display: none !important;
      @media screen and (max-width: $screen-sm-min) {
        display: block !important;
        text-align: center;
        small {
          color: #fff;
          a {
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }
    .log-in {
      small {
        font-weight: 400;
        color: #8DD9AB;
        a {
          color: #fff;
          font-weight: 600;
        }
      };
      @media screen and (max-width: $screen-sm-min) {
        margin-top:15px;
        small {
          color: #fff;
          font-weight: 500;
          font-size: 14px;
          margin-left: -8px;
          .registerlink {
            margin-left: 14px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
