.uploader {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  align-self: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap:.625rem;
  background:#eaf1ff;
  padding: 1.5rem;
  max-width: 60rem;
  margin: 0 auto;

  $this: &;

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .item {
    font-size: 5.75rem;
    font-weight: 100;
    background-color: #fff;
    border-radius: .7rem;
    width: 9rem;
    height: 9rem;
    border:2px dashed transparent;
    text-align: center;
    * {
      pointer-events: none;
    }
    &.folder {
      
    }

    $elements: 128;
    @for $i from 0 to $elements {
      @at-root .uploader.loading .item:nth-child(#{$i + 1}) {
        animation-name: fadein;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;
        animation-fill-mode: both;
        animation-delay: $i/16 + s;
      }
    }

    @keyframes blinker {
      50% {
        border-color: #f5f5f5;
      }
    }    
    &.selected {
      border:2px dashed #7690c4;
      background: #f5f5f5;
      animation: blinker 1.75s ease-in-out infinite;
    }
    &:hover {
      background: #e0e0f3;
      border:2px dotted #b9cffc;
    }
    &.dragdrop {
      &.selected {
        animation-duration: .75s;
        animation-iteration-count: 2;
      }
    }
    svg {
      margin:-3rem auto 0;
    }
    &.file svg {
      margin-top:-3.3rem;
      transform: scale(1.2, .86);
    }
    .name {
      font-size: .8rem;
      margin:-3rem auto 0 auto;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(8rem);
      overflow-x: hidden;
      //opacity: .85;
    }
    .details {
      font-size: .6rem;
      font-weight: 500;
      text-overflow: ellipsis;
      //opacity: .50;
    }
  }
}