@import './_variables.scss';

.sidebar {
  display: flex;
  width: 100%; //var(--sidebar-width);
  height: 100vh;
  padding: 2rem 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.875rem;
  flex-shrink: 0;

  border-radius: 1.5rem;
  background: #FFF;
  
  /* Shadow S */
  box-shadow: 0px 4px 23px 10px rgba(55, 68, 100, 0.11);
  transition: width .450s ease-in-out;

  button.newsession {
    .closed & {
      width: 3rem;
      height: 3rem;
      overflow: hidden;
      transition: width .450s ease-in-out, margin .450s ease-in-out;
      margin-top: .8rem;
    }
    display: flex;
    width: calc(100% - 3rem);
    padding: 0.5rem 0.875rem;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    border-radius: var(--Border-radius-M);
    background: var(--primary-gradient);
    transition: width .450s ease-in-out, margin .450s ease-in-out;
    span {
      &.big {
        .closed & {
          display: none;
        }
      }
      &.small {
        display: none;
        .closed & {
          display: block;
        }
        &::before {
          width: 1rem !important;
        }
      }
      color: var(--Shades-Base, #FFF);
      white-space: nowrap;
      /* Heading M */
      font: var(--sidebar-text);
      font-weight: 500;
      &::before {
        content:url('../images/plus.svg');
        position: relative;
        width: 3rem;
        height: 2rem;
        float: left;
        margin-left: -1rem;
      }
    }
  }

  .bar {
    border: var(--shades-contrast-20);
    width: calc(100% - 3rem);
    padding: 0rem 0.875rem;
    margin: 0 auto;    
    transition: margin .450s ease-in-out;
    .closed & {
      margin: .8rem auto;
      transition: margin .450s ease-in-out;
    }
  }

  .history_area {
    margin-top:.75rem !important;
    height:calc(100vh - 10.375rem);
    overflow-y: hidden;
    overflow-x: hidden;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    scrollbar-gutter: stable;
    transition: width .450s ease-in-out, height .450s ease-in-out, margin .450s ease-in-out;
    &:hover {
      overflow-y: auto;
    }
    .closed & {
      margin: .8rem auto;
      transition: width .450s ease-in-out, height .450s ease-in-out, margin .450s ease-in-out;
      scrollbar-gutter: auto;
    }
  }

  .help_area {
    margin-top:.5rem !important;
    height: 3rem;
    text-align: center;
    a {
      text-decoration: none;
    }
    span {
      .closed & {
        transition: width .450s ease-in-out, margin .450s ease-in-out;
        margin: .8rem auto;
      }  
      color: var(--Tertiary-Text);
      white-space: nowrap;
      overflow: hidden;
      /* Heading M */
      font: var(--sidebar-text);
      &.big {
        .closed & {
          display: none;
        }
      }
      &.small {
        display: none;
        .closed & {
          display: block;
        }
        &::before {
          width: 1.6rem !important;
        }
      }
      &::before {
        content:url('../images/question.svg');
        position: absolute;
        margin:-.1rem 0 0 -2rem;
        transition: width .450s ease-in-out, margin .450s ease-in-out;
        .closed & {
          position: relative;
          margin: 0 auto;
        }  
      }      
    }
  }


}