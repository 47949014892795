// $large-logo: '../images/logo-lg-white.png';
// $small-logo: '../images/logo-sm-white.png';

$small-logo-width: 80px;
$small-logo-height: 80px;

$large-logo-width: 200px;
$large-logo-height: 65px;
$primary-color:#548235;
$secondary-color: #FF9D42;
$input-background:#F3F3F3;
$header-color:#70AD47;

$button-secondary-color:#fff;
$circle-border-radius:15px;
:export {
  largeWidth : $large-logo-width;
  largeHeight : $large-logo-height;
  smallWidth : $small-logo-width;
  smallHeight : $small-logo-height;
}
:root {
  --primary-gradient: linear-gradient(90deg, #455071 0%, #182848 100%);
  --button-primary: #fff;
  --sidebar-width: 17.625rem;
  --closedbar-width: 6.1rem;
  --shades-contrast-20: 1px solid rgba(28, 55, 90, 0.16);
  --Border-radius-M: 0.5rem;
  --Tertiary-Text: rgba(28, 48, 74, 0.52);
  --sidebar-text: normal 500 1rem/2rem "DM Sans", "Inter", "serif";
  --default-text: normal 500 1rem/2rem "DM Sans", "Inter", "serif";
  --label-field: normal 500 0.875rem/1rem "Inter", "Georgia", "serif";
  --Heading-Text: #192434;
  --semantic-colors-error-text: #CA150C;
  --header-height: 5.50306rem;
  --chat-title-text: normal 500 1rem "DM Sans", "Inter", "serif";
  --question-primary: linear-gradient(90deg, #455071 0%, #182848 100%);
  --response-primary: #F0F4F9;
  --chat-box-text: normal 500 1rem/1.6rem "DM Sans", "Inter", "serif";
  --send-button-text: normal 500 0.875rem/1.0625rem "Inter", "serif";
  --question-chat-color: #fff;
  --response-chat-color: rgba(25, 39, 57, 0.94);
  --message-area-width: 53.75rem;
  --offset-message-width: -53.75rem;
  --heading-xxl: normal 700 2.5rem/125.303% "DM Sans", "Inter", "serif";
  --heading-xxl-color: #1C304A85;
  --heading-xxl-name-color:#dfdfdf85;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;  
}