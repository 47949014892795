@import './_variables.scss';
//@import "~bootstrap-icons/font/bootstrap-icons.css";

.header {
  display: flex;
  height: var(--header-height);
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  flex-shrink: 0;
  background: #FFF;

  .left-logo, .right-logo {
    height: 2.5rem;
  }
  .border-gap {
    background: #ADA6A6;
    width: 0.05369rem;
    height: 2.2rem;
    margin:.15rem 0;
  }
  .user-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    .display {
      display: flex;
      gap: 0.625rem;
      .name {
        flex:10;
        color: #1A2A4A;
        font: var(--default-text);
        margin: .5rem;
      }
      .button {
        /*flex:1;
        padding:.4rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        text-align: center;
        width: 2.3rem;
        height: 2.3rem;
        background-color:#097FAA;*/
        background: transparent;
        margin: .3rem 0 0 0;
        font:var(--label-field);
        color:var(--button-primary);
        .circle {
          padding: 0.2rem .2rem;
          color: white;
          width: 2.3rem;
          height: 2.3rem;
          line-height: 1.95rem;
          background-color:#097FAA;
          border-radius: 50%;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          position: relative;
        }
      }
      .menu {
        display: none;
      }
      &:hover {
        .menu {
          position: absolute;
          z-index: 1;
          display: flex;
          //width: 13rem;
          //height: 7.625rem;
          padding: 1.5rem 1.7rem 1.5rem 1.5rem;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.625rem;
          margin: 3rem 0 0 -5rem;
    
          border-radius: 1rem;
          background: #FFF;
          
          /* Shadow S */
          box-shadow: 0px 4px 23px 10px rgba(55, 68, 100, 0.11);
          .item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: var(--Heading-Text);
            text-wrap: nowrap;
            font: var(--default-text);
            &::before {
              width: 1.8rem;
              height: 2rem;
              font-family: "bootstrap-icons";
              text-align: center;
              font-size: 1.45rem;
            }
            &.logout {
              color: var(--semantic-colors-error-text);
              &::before {
                content:url('../images/logout.svg');
              }
            }
            &.settings {
              &::before {
                content:url('../images/cog.svg');
              }
            }
            &.users {
              &::before {
                content:"\F4D0";
              }
            }
            &.roles {
              &::before {
                content:"\F2EE";
              }
            }
          }
        }
      }
    }
  }
}